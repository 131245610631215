<template>
  <div>
    <!-- No Dashboard for Super admin yet. 
    Will redirect to Product List temporarily -->
  </div>
</template>

<script>
export default {
  name: 'SuperDashboard',

  mounted() {
    this.$router.push({ name: 'Analytics' });
  },
};
</script>

<style lang="scss" scoped></style>
